import React from 'react';

import {GrLinkedin, GrGithub, GrTwitter, GrInstagram} from "react-icons/gr";



const HeaderSocials = () => {
  return (
    <div className="header__socials">
    <a href= "https://www.linkedin.com/in/prateik005/" target="_blank"> <GrLinkedin/> </a>
    <a href= "https://github.com/Prateik55" target="_blank"> <GrGithub/></a>
    <a href= "https://twitter.com/PrateekRathaur" target="_blank"><GrTwitter/></a>
    <a href= "https://www.instagram.com/fifth_emergent/" target="_blank"><GrInstagram/></a>
    
    </div>
  )
}

export default HeaderSocials