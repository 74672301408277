import React from 'react'
import "./services.css";
import {BiCheck} from "react-icons/bi";
const Services = () => {
  return (
    <section id="services"> 
      <h5> I Can Help You With </h5>
      <h2> Services </h2>
      
      <div className="container services__container">
        <article className="service">
        <div className="service__head">
          <h3>UI/UX</h3>
        </div>

        <ul className="service__list">
          <li>
            <BiCheck className="service__list-icon"/>
             <p> Understanding and identifying the needs of the user. </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p> Developing and creating the overall look and feel of a product. </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p> Creating wireframes, prototypes, and user flows to communicate design ideas. </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p>Gathering user feedback and identify areas for improvement. </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p>Continuously iterating and improving the design   </p>
          </li>
          
        </ul>


        </article>
 {/* /* ===============END of UI/UX==================== */} 

        <article className="service">
        <div className="service__head">
          <h3>Web Development</h3>
        </div>

        <ul className="service__list">
          <li>
            <BiCheck className="service__list-icon"/>
             <p>  Creating and maintaining Landing Pages  </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p> Implementing a feature in existing product </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p> Ensure that the website or web application is functional and user-friendly.  </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p>  Troubleshooting and fixing any issues that may arise.  </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p> Working closely with other members of the development team and stakeholders </p>
          </li>
          
        </ul>
        </article>  

         {/* // ===============END of WEB DEVELOPMENT====================  // */}

        <article className="service">
        <div className="service__head">
          <h3>Copywriting and Content Creation </h3>
        </div>

        <ul className="service__list">
          <li>
            <BiCheck className="service__list-icon"/>
             <p>Writing in clear, concise, and engaging manner that captures the attention of the reader </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p>Writing copy for websites, advertisements, brochures, and other promotional materials. </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p>Deliverable actions on CTA  </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p>Retaining readers of content based product </p>
          </li>
          <li>
            <BiCheck className="service__list-icon"/>
             <p>Replacing that forgotten lorem ipsum with meaning. </p>
          </li>  
        </ul>
     </article>
 {/* ===============END of CONTENT CREATION==================== */}

      </div>
    </section>
  )
}

export default Services