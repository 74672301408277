import React from 'react';
import CV from "../../assets/cv.pdf";

const CTA = () => {
  return (
    <div className="cta">
    <a href={CV} download className="btn">Download CV</a>
    <a href='https://pratix.in/blog' target="_blank" className="btn btn-primary">My Blog</a>
    
    </div>
  )
}

export default CTA