import React from 'react'
import "./portfolio.css"
import IMG1 from "../../assets/portfolio1.PNG"
import IMG2 from "../../assets/portfolio2.PNG"
import IMG3 from "../../assets/portfolio3.PNG"
import IMG4 from "../../assets/portfolio4.PNG"
import IMG5 from "../../assets/portfolio5.png"
import IMG6 from "../../assets/portfolio6.jpg"


const data =[
  {
    id:1,
    image:IMG1,
    title: "Movie App",
    github: "https://github.com/Prateik55/MovieDumpWeb",
    demo:"https://moviedump.netlify.app/"
  },
  {
    id:2,
    image:IMG2,
    title: "Portfolio website",
    github: "https://github.com/Prateik55/Personal_Portfolio_website-",
    demo:"https://prateik55.github.io/Personal_Portfolio_website-/"
  },
  {
    id:3,
    image:IMG3,
    title: "Manage website ",
    github: "https://github.com/Prateik55/Manage-Landing",
    demo:"https://prateik55.github.io/Manage-Landing/"
  },
  {
    id:4,
    image:IMG4,
    title: "Journal Entry App",
    github: "https://github.com/Prateik55/blogsite",
    demo:"https://github.com/Prateik55/blogsite"
  }
]


const Portfolio = () => {
  return (
  <section id="portfolio">
    <h5> My recent Work</h5>
    <h2> Portfolio </h2>
    <div className="container portfolio__container">
   
{data.map(({id,image, title, github, demo}) => {
return (
  <article key={id} className="portfolio__item">
  <div className="portfolio__item-image">
   <img src={image} alt={title}></img>
  </div>
 <h3> {title}</h3>
 <div className="portfolio__item-cta">
 <a href={github} className="btn" target="_blank">Github</a>
 <a href = {demo} className= "btn btn-primary" target="_blank">Live Demo</a>
 </div>
 </article>
)
})}  

    

    </div>
  </section>
  )
}

export default Portfolio;