import React from 'react'
import "./testimonials.css"
import AVTR1 from "../../assets/avatar1.jpg"
import AVTR2 from "../../assets/avatar2.jpg"
import AVTR3 from "../../assets/avatar3.jpg"
import AVTR4 from "../../assets/avatar4.jpg"


import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

const Testimonials = () => {
  return (
    <section  id="testimonials"> 
    <h5> Reviews from Clients </h5>
    <h2> Testimonials </h2>
    
    <Swiper className="container testimonials__container"
     modules={[ Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
     >


      <SwiperSlide className="testimonial">
        <div className="client__avatar">
        <img src={AVTR1} alt="avatar1"/>
        </div>
        <h5 className="client__name">E.Tylor </h5>
        <small className="client__review">
        "I recently had the pleasure of working with Prateek on a brand name suggestion project for my company, and I was blown away by their creativity and wit. He came up with a number of unique and clever ideas that perfectly captured the essence of our brand and resonated with our target audience. His attention to detail and ability to think outside the box truly set him apart from other copywriters I've worked with. I would highly recommend Prateek for anyone in need of a fresh, engaging, and memorable brand name"
        </small>
      </SwiperSlide>

      <SwiperSlide className="testimonial">
        <div className="client__avatar">
        <img src={AVTR2} alt="avatar2"/>
        </div>
        <h5 className="client__name"> John Smith </h5>
        <small className="client__review">
        "I recently had the pleasure of working with Prateek on a landing page for my business, MANAGE. He was incredibly skilled at bringing our design vision to life, using their expertise in HTML, CSS, and JavaScript to create a visually stunning and user-friendly landing page. He was able to effectively translate our design concepts into code, and his attention to detail ensured that the final product was of the highest quality. In addition, Prateek is a great communicator and is always available to answer any questions or address any concerns. I would highly recommend Prateek for anyone in need of top-notch front-end development services."
        </small>  
      </SwiperSlide>

      <SwiperSlide className="testimonial">
        <div className="client__avatar">
        <img src={AVTR3} alt="avatar3"/>
        </div>
        <h5 className="client__name"> Steve Steele</h5>
        <small className="client__review">
            "He was able to effectively balance aesthetics and functionality to create a website that was not only beautiful, but also easy to navigate and use.Recommends him"
        </small>
      </SwiperSlide>

      <SwiperSlide className="testimonial">
        <div className="client__avatar">
        <img src={AVTR4} alt="avatar4"/>
        </div>
        <h5 className="client__name"> Jim Ienberg</h5>
        <small className="client__review">
            "I just wanted to take a moment to express my gratitude for your help in refactoring our code. Your expertise and attention to detail made a huge difference, and I appreciate the time and effort you put into improving the quality and maintainability of our codebase."
        </small>
      </SwiperSlide>

      

    </Swiper>
    </section>
  )
  

}

export default Testimonials